/* @flow */
import React, { createContext, type Node, PureComponent } from 'react'
import Cookie from '../components/utils/Cookie'
import ExternalService from '../components/utils/ExternalService'
import request from '../components/utils/Request'
import { getAdsPersonalizationConsent } from '../../lib/utils/didomiConsent'
import { sendWidgetClick } from '../components/utils/ga4Events'
const { setCpexTuple } = require('../utils/cpexTuple')
const _ = require('lodash')

type EmailProviderProps = {|
  children: Node,
  options: {
    proxyOptions: Object,
    serviceUrlPattern: string,
    xhrLogoutUri: string,
    isTracked: boolean,
    variant: boolean,
    cookieNameConstants: Object,
    cookies: Object,
    ecoIdentityReloadIntervalMs: string
  }
|}

type EmailProviderState = {|
  inbox: Object,
  userInfo: {
    email?: string,
    firstName?: string,
    lastName?: string,
    avatar?: string,
    hasMailbox?: boolean,
    userUuid?: string,
    userService?: string
  },
  isLoadingInbox: boolean,
  isLoadingUserInfo: boolean
|}

const RELOAD_INTERVAL_MS = 120000

const EmailContext: Object = createContext()

const EmailConsumer = EmailContext.Consumer

class EmailProvider extends PureComponent<EmailProviderProps, EmailProviderState> {
  emailRefreshingInterval: IntervalID
  userInfoRefreshingInterval: IntervalID
  ecoIdentityReloadIntervalMs: number
  handleLogoutClick: (event: Object) => void
  constructor (props: EmailProviderProps) {
    super(props)

    const { ecoIdentityReloadIntervalMs } = props.options
    this.ecoIdentityReloadIntervalMs = Number(ecoIdentityReloadIntervalMs)

    this.state = {
      inbox: {},
      userInfo: {},
      isLoadingInbox: false,
      isLoadingUserInfo: false
    }

    this.handleLogoutClick = this.handleLogoutClick.bind(this)
  }

  componentDidMount () {
    this.loadInbox()
    this.loadUserInfo()
  }

  componentWillUnmount () {
    clearInterval(this.emailRefreshingInterval)
    clearInterval(this.userInfoRefreshingInterval)
  }

  componentDidUpdate (_: any, prevState: EmailProviderState) {
    const { userUuid: newUserUuid, userService: newUserService } = this.state.userInfo
    const { userUuid: prevUserUuid, userService: prevUserService } = prevState.userInfo
    const { options } = this.props

    if (prevUserUuid !== newUserUuid || prevUserService !== newUserService) {
      if (newUserUuid && newUserService) {
        window.didomiOnReady.push(() => {
          if (getAdsPersonalizationConsent()) {
            setCpexTuple({
              userUuid: newUserUuid,
              userService: newUserService,
              options
            })
          }
        })
      }
    }
  }

  loadInbox () {
    const {
      options: {
        proxyOptions,
        serviceUrlPattern,
        variant
      }
    } = this.props

    if (((variant === 'EcoIdComplete' || variant === 'EcoIdTransition') && this.shouldProcessInboxRequestEcoIdentity()) || this.shouldProcessInboxRequest()) {
      const serviceHelper = new ExternalService(proxyOptions)
      const serviceUri = serviceHelper.getServiceUrl(serviceUrlPattern)
      this.loadInboxForFirstTime(serviceUri)
      this.startPeriodicallyLoadingInbox(serviceUri)
    }
  }

  loadUserInfo () {
    const {
      options: {
        variant
      }
    } = this.props

    if ((variant === 'EcoIdComplete' || variant === 'EcoIdTransition') && this.shouldProcessUserInfoRequest()) {
      this.loadIdentitaUserInfo()
      this.startPeriodicallyLoadingUserInfo()
    }
  }

  shouldProcessInboxRequest () {
    const {
      options: {
        cookieNameConstants
      }
    } = this.props

    const cookieHandler = new Cookie()
    const sessionCookieName = cookieNameConstants.sessionCookie
    const uprofileCookieName = cookieNameConstants.uprofileCookie

    return cookieHandler.isSet(sessionCookieName) || cookieHandler.isSet(uprofileCookieName)
  }

  shouldProcessInboxRequestEcoIdentity () {
    const {
      options: {
        cookieNameConstants,
        cookies
      }
    } = this.props

    const sessionCookieName = cookieNameConstants.sessionCookie
    const uprofileCookieName = cookieNameConstants.uprofileCookie

    return cookies[sessionCookieName] || cookies[uprofileCookieName]
  }

  shouldProcessUserInfoRequest () {
    const {
      options: {
        cookieNameConstants,
        cookies
      }
    } = this.props

    const sessionCookieName = cookieNameConstants.sessionCookie
    const aprofileCookieName = cookieNameConstants.aprofileCookie

    return cookies[sessionCookieName] || cookies[aprofileCookieName]
  }

  loadInboxForFirstTime (serviceUri: string) {
    const { options } = this.props

    this.setState({
      isLoadingInbox: true
    })

    this.sendRequest(serviceUri).then(inbox => {
      this.setState({
        inbox
      })
      window.localStorage.setItem('tracked', 'true')
      const {
        'user-service': userService,
        'user-uuid': userUuid
      } = inbox
      if (userUuid && userService) {
        window.didomiOnReady.push(() => {
          if (getAdsPersonalizationConsent()) {
            setCpexTuple({ userUuid, userService, options })
          }
        })
      }
    }).finally(() => {
      this.setState({
        isLoadingInbox: false
      })
    })
  }

  startPeriodicallyLoadingInbox (serviceUri: string) {
    this.emailRefreshingInterval = setInterval(() => {
      this.sendRequest(serviceUri).then(inbox => {
        this.setState({
          inbox
        })
      }).catch(() => {
      })
    }, RELOAD_INTERVAL_MS)
  }

  sendRequest (serviceUri: string): Promise<Object> {
    return new Promise((resolve, reject) => {
      const config = {
        url: serviceUri,
        withCredentials: true,
        proxy: false
      }

      request(config).then(response => {
        const inbox = (typeof response.data === 'object') ? response.data : {}
        return resolve(inbox)
      }).catch(error => {
        return reject(error)
      })
    })
  }

  handleLogoutClick (event: Object) {
    this.setState({
      isLoadingInbox: true
    })

    const {
      options: {
        xhrLogoutUri
      }
    } = this.props
    clearInterval(this.emailRefreshingInterval)
    const eventTargetUri = event.target.getAttribute('href')
    this.sendLogoutRequest(xhrLogoutUri).then(() => {
      this.loadInbox()
    }).catch(() => {
      document.location.href = eventTargetUri
    }).finally(() => {
      this.setState({
        isLoadingInbox: false
      })
    })
    sendWidgetClick({
      name: 'email',
      click: {
        type: 'link',
        value: 'logout'
      }
    })
  }

  sendLogoutRequest (logoutUri: string): Promise<void> {
    return new Promise((resolve, reject) => {
      request({
        url: logoutUri,
        withCredentials: true
      }).then(() => {
        return resolve()
      }).catch(error => {
        return reject(error)
      })
    })
  }

  async loadIdentitaUserInfo () {
    this.setState({
      isLoadingUserInfo: true
    })

    this.fetchUserInfo().finally(() => {
      this.setState({
        isLoadingUserInfo: false
      })
    })
  }

  startPeriodicallyLoadingUserInfo () {
    if (!_.isNumber(this.ecoIdentityReloadIntervalMs) || !_.isFinite(this.ecoIdentityReloadIntervalMs)) {
      return
    }

    this.userInfoRefreshingInterval = setInterval(() => {
      if (this.shouldProcessUserInfoRequest()) {
        this.fetchUserInfo()
      }
    }, this.ecoIdentityReloadIntervalMs)
  }

  async fetchUserInfo () {
    return fetch('/userinfo').then((response) =>
      response.json().then(({
        email,
        firstname,
        lastname,
        avatar,
        has_mailbox: hasMailbox,
        service: userService,
        uuid: userUuid
      }) => {
        this.setState({
          userInfo: {
            email,
            firstName: firstname,
            lastName: lastname,
            avatar,
            hasMailbox,
            userService,
            userUuid
          }
        })
      })
    )
  }

  render () {
    const {
      props: {
        children
      },
      state
    } = this

    const { isLoadingInbox, isLoadingUserInfo } = state
    const isLoading = isLoadingInbox && isLoadingUserInfo

    const contextValue = Object.assign({}, state, {
      handleLogoutClick: this.handleLogoutClick,
      isLoading
    })

    return (
      <EmailContext.Provider value={contextValue}>
        { children }
      </EmailContext.Provider>
    )
  }
}

export {
  EmailProvider,
  EmailConsumer
}
