import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'
import { login } from '../../../utils/login'
import { getLinkTarget } from '../../utils/getLinkTarget'
import {
  MORE_INFO_ECO_ID_URL
} from '../Header/HeaderLogin/LoginModal/LoginModal'
import { EmailConsumer } from '../../../context/Email'
import { isResponseFull, unreadIndicator } from '../Email/EmailUtils'
import Loader from '../../utils/Loader'
import Headline from '../../utils/Headline'
import HeadlineUnreadIndicator
  from '../Email/EmailSummary/StyledHeadlineUnreadIndicator'
import { HeadlineWrapper } from '../Email/EmailSummary'

export const EcoIdLoginPromo = ({
  ecoIdentity: {
    clientId,
    login: {
      location: loginLocation
    }
  },
  activeDynamicLayout,
  emailWidget
}) => {
  const handleLogin = useCallback(() => {
    login({ loginLocation, clientId })
  }, [loginLocation, clientId])

  const handleKetchupClick = useCallback(() => {
    window
      .open(
        'https://freemail.help.economia.cz/articles/125188-proc-je-soucasti-boxiku-o-novem-prihlaseni-lahev-kecupu?utm_source=hp&utm_id=bobika-kecup',
        '_blank'
      )
      .focus()
  }, [])

  return (
    <EmailConsumer>
      {({ inbox, isLoading }) => {
        const isLoggedIn = isResponseFull(inbox)

        if (isLoggedIn) {
          return null
        }

        const {
          summary: {
            unread = 0
          } = {},
          summary = {}
        } = inbox

        return (
          <Fragment>
            <HeadlineWrapper>
              <Headline widget={emailWidget}>
                {
                  (Object.keys(summary).length > 0) &&
                  <HeadlineUnreadIndicator className={unread === 0 ? 'grey' : ''}>
                    {unreadIndicator(unread)}
                  </HeadlineUnreadIndicator>
                }
              </Headline>
            </HeadlineWrapper>
            <StyledContainer>
              {isLoading && <Loader/>}
              <StyledContainer2>
                <StyledImage
                  src={`${UNVERSIONED_STATICS_PATH}images/ecoidpromo/moving_ketchup.webp`}
                  alt='Animace kečupu'
                  onClick={handleKetchupClick}
                />
                <StyledText>
                  <StyledMainText>Přihlášení<br></br>(nejen)<br></br>do e-mailu</StyledMainText>
                  <StyledSecondaryText>Už jste převedli svůj účet do nové podoby?</StyledSecondaryText>
                </StyledText>
                <StyledButton onClick={handleLogin}>Přihlaste se zde</StyledButton>
              </StyledContainer2>
              <StyledLineBreak />
              <StyledMoreInformation
                href={MORE_INFO_ECO_ID_URL}
                target={getLinkTarget(activeDynamicLayout)}
              >
                Více informací
              </StyledMoreInformation>
            </StyledContainer>
          </Fragment>
        )
      }}
    </EmailConsumer>
  )
}

const StyledMainText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
  text-transform: uppercase;
`

const StyledSecondaryText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
`

const StyledImage = styled.img`
  width: auto;
  max-width: 60px;
  height: 64px;
  margin-left: -8px;
  cursor: pointer;
`

const StyledContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.ecoIdLoginPromo.container.backgroundColor};
  padding: 20px 12px 12px;
  gap: 8px;
  margin-bottom: 12px;
`

const StyledText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.ecoIdLoginPromo.text.color};
`

const StyledButton = styled.button`
  padding: 0 12px;
  min-height: 60px;
  height: 100%;
  min-width: 88px;
  background-color: ${({ theme }) => theme.ecoIdLoginPromo.button.backgroundColor};
  border: none;
  color: ${({ theme }) => theme.ecoIdLoginPromo.button.color};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;

  :hover {
    background-color: ${({ theme }) => theme.ecoIdLoginPromo.button.backgroundHoverColor};
  }
`

const StyledMoreInformation = styled.a`
  color: ${({ theme }) => theme.ecoIdLoginPromo.moreInfo.color};
  text-decoration: underline;
  font-size: 14px;

  :hover {
    color: ${({ theme }) => theme.ecoIdLoginPromo.moreInfo.hoverColor};
  }
`

const StyledLineBreak = styled.div`
  width: 100%;
`
