const _ = require('lodash')

const WindowEcoProps = {
  TOKEN: 'token',
  SERVICE: 'service',
  SID: 'sid'
}

const setCpexTuple = ({ userUuid, userService, options }) => {
  const { isTracked, cookieNameConstants, cookies } = options

  if (isTracked) {
    window.eco = window.eco || {}
    window.eco[WindowEcoProps.TOKEN] = userUuid
    window.eco[WindowEcoProps.SERVICE] = userService
  }

  const sidCookieName = cookieNameConstants.sid
  if (cookies[sidCookieName]) {
    window.eco = window.eco || {}
    window.eco[WindowEcoProps.SID] = cookies[sidCookieName]
  }

  if (!_.isEmpty(window.eco)) {
    window.dispatchEvent(new Event('ecoIdReady'))
  }
}

module.exports = {
  setCpexTuple
}
